import React from "react";
import "./App.css";
import video from "./assets/coming-soon.webm";

const App = () => {
  return (
    <div className="app">
      <video className="background-video" autoPlay loop muted>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default App;
